<!--
 * @Author: LazyQ
 * @Date: 2020-04-13 13:21:26
 * @LastEditTime: 2020-04-21 10:24:16
 * @LastEditors: LazyQ
 -->
<style lang="less" scoped>
.left-20 {
  margin-left: 20px;
}
.left-10 {
  margin-left: 10px;
}
.top-10 {
  margin-top: 10px;
}
.top-20 {
  margin-top: 20px;
}
.width-200 {
  width: 200px;
}
</style>
<style>
.ant-table-tbody {
  /* font-size: 10px !important; */
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500 !important;
  color: rgba(51, 51, 51, 1) !important;
}
.ant-table-row {
  height: 37px !important;
}
</style>

<template>
  <span>
    <a-button icon="export" type="primary" @click="openFilter">导出</a-button>
    <a-modal
      title="订单导出"
      :maskClosable="false"
      :visible="visible"
      :confirmLoading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel"
    >
    <template slot="footer">
      <a-button key="submit" type="primary" :loading="loading" @click="handleOk">
        确认
      </a-button>
    </template>
    <div class="top-20">
      <span>选择订单时间</span>
      <a-space direction="vertical" :size="12">
        <a-range-picker class="left-20"  @change="handlePanelChange"/>
      </a-space>
    </div>
    </a-modal>  
  </span>
</template>

<script>
import { getArea } from "@/api/base.js";
import Vue from 'vue';

export default {
  props: {
  },
  data() {
    return {
      visible: false,
      confirmLoading: false,
      loading: false,
      begin_time: '',
      end_time: ''
    };
  },
  created() {
  },
  methods: {
    handlePanelChange(value, mode) {
      if (mode.length > 1) {
        this.begin_time = mode[0]
        this.end_time = mode[1] 
      }
    },
    /// 选择客户
    openFilter() {
      this.visible = true;
    },
    handleOk(e) {
      this.$emit('filterHandler', {begin: this.begin_time, end: this.end_time});
      this.visible = false;
    },
    handleCancel(e) {
      this.visible = false;
    },
    handleClear(e) {
      this.begin_time = undefined;
      this.end_time = undefined;
      this.$emit('filterHandler', "{}");
      this.visible = false;
    },
    
  }
};

</script>